import { QKEY } from '@/constants/queryKeys'
import { Account } from '@/models/account'

import { useAuthorizedQuery } from '../../useAuthorizedQuery'

export function useAccounts() {
  return useAuthorizedQuery<Account[]>(QKEY.ACCOUNTS.getAll, {
    queryFn: client => client.get<Account[]>('/account').then(res => res.data),
    error: 'Failed to load accounts',
  })
}
