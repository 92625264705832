import { Spinner } from '@nextui-org/spinner'
import React from 'react'

export const Loader = () => {
  return (
    <div className="flex h-full min-h-0 w-full items-center justify-center overflow-hidden">
      <Spinner size="lg" />
    </div>
  )
}
