import { QKEY } from '@/constants/queryKeys'
import { CKEditorDocxToHtmlMetadata, DocumentVersionLight } from '@/models/api'
import { ProcessingStatus } from '@/models/enums'

import { useAuthorizedQuery } from '../useAuthorizedQuery'

export function useDocumentVersions(documentId: string | undefined) {
  return useAuthorizedQuery<DocumentVersionLight[] | null>(
    QKEY.DOCUMENT_VERSIONS.getAll(documentId),
    {
      queryFn: client => {
        if (!documentId) return null

        return client
          .get<
            {
              id: string
              num_pages: number
              version_number: number
              contents: string[]
              ckeditor_conversion: CKEditorDocxToHtmlMetadata
              document_info_processing_status: ProcessingStatus
              flags_processing_status: ProcessingStatus
              redlines_processing_status: ProcessingStatus
              has_track_changes: boolean
              has_comments: boolean
              created_at: string
              updated_at: string
              playbook_id: number
              is_from_import: boolean
            }[]
          >(`/document/${documentId}/versions`)
          .then(res =>
            res.data.map(document => ({
              id: document.id,
              numPages: document.num_pages,
              versionNumber: document.version_number,
              contents: document.contents,
              ckeditorConversion: document.ckeditor_conversion,
              documentInfoProcessingStatus:
                document.document_info_processing_status,
              flagsProcessingStatus: document.flags_processing_status,
              redlinesProcessingStatus: document.redlines_processing_status,
              hasTrackChanges: document.has_track_changes,
              hasComments: document.has_comments,
              createdAt: document.created_at,
              updatedAt: document.updated_at,
              playbookId: document.playbook_id,
              isFromImport: document.is_from_import,
            })),
          )
      },
      error: 'Failed to load document versions',
    },
  )
}
