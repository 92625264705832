import { useAtom } from 'jotai'
import { useEffect, useMemo } from 'react'
import { useMediaQuery } from 'usehooks-ts'

import { MOBILE_MEDIA_QUERY } from '@/constants'
import {
  globalStore,
  isSidebarCollapsedAtom,
  isSidebarHiddenAtom,
} from '@/services/state/global'

export const useSidebar = () => {
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY, {
    initializeWithValue: false,
  })

  const isMidResolution = useMediaQuery('(max-width: 1024px)', {
    initializeWithValue: false,
  })

  // TODO: Using specific store because we are using the global store with ssr,
  // some childs loose the ref to the correct store
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useAtom(
    isSidebarCollapsedAtom,
    {
      store: globalStore,
    },
  )

  const [isSidebarHidden, setIsSidebarHidden] = useAtom(isSidebarHiddenAtom, {
    store: globalStore,
  })

  const isCompact = useMemo<boolean>(
    () => !isMidResolution && isSidebarCollapsed,
    [isMidResolution, isSidebarCollapsed],
  )

  const isHidden = useMemo<boolean>(
    () => (isMidResolution || isMobile) && isSidebarHidden,
    [isMidResolution, isSidebarHidden, isMobile],
  )

  useEffect(() => {
    if (isMidResolution) setIsSidebarHidden(true)
  }, [isMidResolution, setIsSidebarHidden])

  return {
    isCompact,
    isHidden,
    canHide: isMidResolution,
    isSidebarCollapsed,
    isSidebarHidden,
    setIsSidebarCollapsed,
    setIsSidebarHidden,
  }
}
