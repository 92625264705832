import { Icon } from '@iconify/react'
import { Accordion, AccordionItem } from '@nextui-org/accordion'
import {
  Listbox,
  ListboxItem,
  ListboxProps,
  ListboxSection,
  ListboxSectionProps,
} from '@nextui-org/listbox'
import { ChevronRightIcon } from '@nextui-org/shared-icons'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React, { memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Avatar } from '@nextui-org/avatar'

import { SidebarItem } from '@/models/components'
import { SidebarItemType } from '@/models/enums'
import { cn, getInitials } from '@/utils'
import { UITooltip } from '@/components/ui'
import { useGetAllViews } from '@/hooks/queries'

import { baseNavigationItems } from './navigation-items'

type Selection = 'all' | Set<string | number>

export type SidebarProps = Omit<ListboxProps<SidebarItem>, 'children'> & {
  isCompact: boolean
  hideEndContent?: boolean
  iconClassName?: string
  sectionClasses?: ListboxSectionProps['classNames']
  classNames?: ListboxProps['classNames']
  onSelect?: (key: string) => void
}

const Navigation = React.forwardRef<HTMLElement, SidebarProps>(
  (
    {
      isCompact,
      onSelect,
      hideEndContent,
      sectionClasses: sectionClassesProp = {},
      itemClasses: itemClassesProp = {},
      iconClassName,
      classNames,
      className,
      ...props
    },
    ref,
  ) => {
    const { data: allFavoriteViews } = useGetAllViews()

    const router = useRouter()

    const pathname = usePathname()

    const currentPath = pathname.substring(1)

    const getCurrentPath = useMemo(() => currentPath, [currentPath])

    const items = useMemo(() => {
      if (!allFavoriteViews || allFavoriteViews.length === 0) {
        return baseNavigationItems
      }

      const [allDocumentsView, ...subDocumentViews] = allFavoriteViews

      const allDocumentsItem = {
        key: allDocumentsView.slug,
        href: '/all-documents',
        icon: 'solar:folder-with-files-line-duotone',
        title: allDocumentsView.name,
        type: SidebarItemType.Nest,
        items: subDocumentViews.map(view => ({
          key: `views/${view.id}`,
          href: `/views/${view.id}`,
          title: view.name,
        })),
      }

      const workspaceSection = baseNavigationItems[0]

      const newNavigation = [
        {
          ...workspaceSection,
          items: [allDocumentsItem, ...workspaceSection.items!],
        },
      ]

      return newNavigation
    }, [allFavoriteViews])

    const sectionClasses = {
      ...sectionClassesProp,
      base: cn(sectionClassesProp?.base, 'w-full', {
        'p-0 max-w-[44px]': isCompact,
      }),
      group: cn(sectionClassesProp?.group, {
        'flex flex-col gap-1 data-[has-title=true]:pt-0': isCompact,
      }),
      heading: cn(sectionClassesProp?.heading, {
        hidden: isCompact,
      }),
    }

    const itemClasses = {
      ...itemClassesProp,
      base: cn(itemClassesProp?.base, {
        'w-11 h-11 gap-0 p-0': isCompact,
      }),
    }

    const renderItem = React.useCallback(
      (item: SidebarItem) => {
        const isNestType =
          item.items &&
          item.items?.length > 0 &&
          item?.type === SidebarItemType.Nest

        if (isNestType) return renderNestItem(item)

        const isSelected = currentPath === item.key

        return (
          <ListboxItem
            {...item}
            key={item.key + '-item'}
            as={Link}
            onClick={() => {
              return false
            }}
            endContent={
              isCompact || hideEndContent ? null : item.endContent ?? null
            }
            startContent={
              isCompact ? null : item.icon ? (
                <Icon
                  className={cn(
                    'text-default-500 transition-colors group-data-[selected=true]:text-primary-500',
                    isSelected
                      ? 'text-primary-400'
                      : 'group-data-[hover=true]:text-foreground-300',
                    iconClassName,
                  )}
                  icon={item.icon}
                  width={22}
                />
              ) : (
                item.startContent ?? null
              )
            }
            className="ui"
            classNames={{
              title: 'text-inherit text-small font-medium',
              base: cn(
                'px-2.5 py-1.5 rounded-lg transition-colors',
                'data-[hover=true]:bg-transparent data-[selectable=true]:focus:bg-transparent',
                'text-foreground-500 data-[hover=true]:text-default-foreground data-[selectable=true]:focus:text-foreground',
                {
                  'w-11 h-11 gap-0 p-0': isCompact,
                },
                {
                  'text-primary-500 data-[hover=true]:text-primary-500 data-[selectable=true]:focus:text-primary-500 relative before:content-[""] before:absolute before:bg-primary-400 before:w-px before:top-0 before:bottom-0 before:left-[-17px]':
                    isSelected && !isCompact,
                },
              ),
            }}
            aria-label={item.title}
            title={null}
          >
            {isCompact ? (
              <UITooltip content={item.title} placement="right">
                <div className="flex w-full h-11 items-center justify-center">
                  {item.icon ? (
                    <Icon
                      className={cn(
                        'text-default-500 transition-colors group-data-[hover=true]:text-default-foreground group-data-[selected=true]:text-primary-500 ',
                        isSelected && 'text-primary-500',
                        iconClassName,
                      )}
                      icon={item.icon}
                      width={24}
                    />
                  ) : (
                    item.startContent ?? (
                      <Avatar
                        isBordered
                        className={cn('w-6 h-6 text-tiny')}
                        name={item.title}
                        getInitials={getInitials}
                        classNames={{
                          base: cn(
                            'bg-transparent transition-colors text-default-500 ring-1',
                            !isSelected
                              ? 'data-[hover=true]:ring-foreground data-[hover=true]:text-default-foreground'
                              : 'ring-primary-400 text-primary-500',
                          ),
                        }}
                      />
                    )
                  )}
                </div>
              </UITooltip>
            ) : (
              item.title
            )}
          </ListboxItem>
        )
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        isCompact,
        hideEndContent,
        iconClassName,
        itemClasses?.base,
        pathname,
        currentPath,
      ],
    )

    const renderNestItem = React.useCallback(
      (item: SidebarItem) => {
        const isNestType =
          item.items &&
          item.items?.length > 0 &&
          item?.type === SidebarItemType.Nest

        const { href, ...itemProps } = item
        const isRootNavigable = !!href
        const isSelected = currentPath === item.key

        const onSelectionChangeHandler = () => {
          // HINT: using windows because inside the method is not getting the latest value of currentPath even with
          // the use of useCallbacks or dependencies
          const currentUrlPath = window.location.pathname.substring(1)
          if (currentUrlPath !== item.key) {
            router.push(item.href as string)
          }
        }

        return (
          <ListboxItem
            {...itemProps}
            key={item.key}
            classNames={{
              base: cn(
                {
                  'h-auto p-0': !isCompact && isNestType,
                },
                {
                  'inline-block w-11 p-0': isCompact && isNestType,
                },
                {
                  'data-[hover=true]:bg-transparent data-[selectable=true]:focus:bg-transparent':
                    isNestType && isRootNavigable,
                },
              ),
              title: 'text-small font-medium',
            }}
            endContent={
              isCompact || isNestType || hideEndContent
                ? null
                : item.endContent ?? null
            }
            startContent={
              isCompact || isNestType ? null : item.icon ? (
                <Icon
                  className={cn(
                    'text-default-500 transition-colors group-data-[selected=true]:text-foreground group-data-[hover=true]:text-foreground-300',
                    isSelected && 'text-primary-500',
                    iconClassName,
                  )}
                  icon={item.icon}
                  width={24}
                />
              ) : (
                item.startContent ?? null
              )
            }
            aria-label={item.title}
            title={isCompact || isNestType ? null : item.title}
          >
            {isNestType ? (
              <Accordion
                key={`${item.key}-group`}
                className="p-0"
                selectionMode="single"
                disableIndicatorAnimation={isCompact}
                defaultExpandedKeys={
                  item.href &&
                  (isSelected ||
                    item.items?.some(
                      childItem => childItem.key === currentPath,
                    ))
                    ? [item.key]
                    : []
                }
                onSelectionChange={onSelectionChangeHandler}
              >
                <AccordionItem
                  key={item.key}
                  aria-label={item.title}
                  indicator={
                    <ChevronRightIcon
                      className={cn(
                        isCompact ? 'rotate-90' : 'rotate-180',
                        'h-4 w-4 transition-colors group-hover/head:text-default-foreground',
                      )}
                    />
                  }
                  classNames={{
                    heading: cn('group/head relative', {
                      'pr-3': !isCompact,
                    }),
                    trigger: cn('p-0', {
                      'gap-0 flex items-center justify-center h-11 w-11':
                        isCompact,
                    }),
                    content: isCompact ? 'py-0' : 'py-0 pl-4',
                    startContent: isCompact ? 'flex-shrink' : '',
                    titleWrapper: isCompact ? 'hidden absolute' : '',
                    indicator: isCompact ? 'absolute -right-1 bottom-0' : '',
                  }}
                  onClick={e => {
                    e.preventDefault()
                  }}
                  startContent={
                    isCompact && item.icon ? (
                      <Icon
                        className={cn(
                          'text-default-500 transition-colors',
                          isRootNavigable
                            ? isSelected
                              ? 'text-primary-500'
                              : 'group-hover/head:text-default-foreground'
                            : 'group-data-[selected=true]:text-foreground',
                          iconClassName,
                        )}
                        icon={item.icon || ''}
                        width={24}
                      />
                    ) : null
                  }
                  title={
                    isCompact ? null : item.icon ? (
                      <div className="flex items-center gap-2 px-2.5 py-1.5">
                        <Icon
                          className={cn(
                            'text-default-500 transition-colors',
                            isRootNavigable
                              ? isSelected
                                ? 'text-primary-400'
                                : 'group-hover/head:text-foreground-300'
                              : 'group-data-[selected=true]:text-foreground',
                            iconClassName,
                          )}
                          icon={item.icon}
                          width={22}
                        />
                        <span
                          className={cn(
                            'text-small font-medium text-foreground-500 transition-colors',
                            isRootNavigable
                              ? isSelected
                                ? 'text-primary-500'
                                : 'group-hover/head:text-foreground'
                              : '',
                          )}
                        >
                          {item.title}
                        </span>
                      </div>
                    ) : (
                      item.startContent ?? null
                    )
                  }
                >
                  {item.items && item.items?.length > 0 ? (
                    <Listbox
                      aria-label={item.title}
                      className="mt-0.5"
                      classNames={{
                        base: isCompact && isNestType ? 'p-0' : '',
                        list: !isCompact
                          ? 'border-l border-default-200 pl-4'
                          : '',
                      }}
                      items={item.items}
                      variant="flat"
                    >
                      {item.items.map(renderItem)}
                    </Listbox>
                  ) : (
                    renderItem(item)
                  )}
                </AccordionItem>
              </Accordion>
            ) : null}
          </ListboxItem>
        )
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        isCompact,
        hideEndContent,
        iconClassName,
        router,
        pathname,
        getCurrentPath,
      ],
    )

    const onSelectionChangeHandler = React.useCallback(
      (keys: Selection) => {
        const key = Array.from(keys)[0]
        onSelect?.(key as string)
      },
      [onSelect],
    )

    return (
      <>
        {isCompact && (
          <div className="px-1">
            <div
              className="shrink-0 bg-divider border-none w-full h-divider mt-4 mb-1"
              role="separator"
            />
          </div>
        )}

        <Listbox
          key={isCompact ? 'compact' : 'default'}
          ref={ref}
          hideSelectedIcon
          aria-label="Sidebar Navigation"
          as="nav"
          className={cn('list-none', className)}
          classNames={{
            ...classNames,
            list: cn('items-center', classNames?.list),
          }}
          color="primary"
          selectedKeys={[currentPath]}
          selectionMode="single"
          variant="flat"
          onSelectionChange={onSelectionChangeHandler}
          {...props}
        >
          {items.map(item => {
            const hasChildren = !!item.items && item.items?.length > 0
            const isNest = item?.type === SidebarItemType.Nest

            if (isNest && hasChildren) {
              return renderNestItem(item)
            }
            return hasChildren ? (
              <ListboxSection
                key={item.key + '-section'}
                classNames={sectionClasses}
                showDivider={isCompact}
                title={item.title}
              >
                {item.items!.map(renderItem)}
              </ListboxSection>
            ) : (
              renderItem(item)
            )
          })}
        </Listbox>
      </>
    )
  },
)

Navigation.displayName = 'SidebarNavigation'

export const SidebarNavigation = memo(Navigation)
