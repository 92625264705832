import { Icon } from '@iconify/react'
import { Switch } from '@nextui-org/switch'
import { Tooltip } from '@nextui-org/tooltip'
import React from 'react'

import { UIButton } from '@/components/ui'
import { useIsDarkTheme } from '@/hooks'
import { SidebarChildrenProps } from '@/models/components'
import { cn } from '@/utils'

export const SidebarThemeSelector = ({ isCompact }: SidebarChildrenProps) => {
  const { isDark, toggleTheme } = useIsDarkTheme()

  return isCompact ? (
    <Tooltip content="Theme" isDisabled={!isCompact} placement="right">
      <UIButton
        fullWidth
        variant="flat"
        className="justify-center truncate text-default-500 data-[hover=true]:text-foreground"
        isIconOnly={isCompact}
        onClick={toggleTheme}
      >
        <Icon
          className="text-default-500"
          icon={`material-symbols-light:${
            isDark ? 'dark' : 'light'
          }-mode-outline`}
          width={24}
        />
      </UIButton>
    </Tooltip>
  ) : (
    <div className="group/toggle bg- flex items-center justify-between gap-2 rounded-lg px-3 py-3">
      <div className="text-small font-medium text-default-800 group-hover/toggle:font-semibold">
        Theme
      </div>
      <Switch
        color="default"
        size="md"
        defaultSelected={isDark}
        checked={isDark}
        onChange={toggleTheme}
        thumbIcon={({ isSelected, className }) =>
          isSelected ? (
            <Icon
              className={className}
              icon="material-symbols-light:dark-mode-outline"
            />
          ) : (
            <Icon
              className={className}
              icon="material-symbols-light:light-mode-outline"
            />
          )
        }
        classNames={{
          base: cn(
            'ring-1 ring-default-200 rounded-full hover:ring-2 hover:ring-primary-500',
            { 'ring-default-300': isDark },
          ),
          wrapper: cn('mr-0 h-6', {
            '!bg-default-100': !isDark,
            '!bg-default-200': isDark,
          }),
          thumb: cn({
            'bg-default-50': isDark,
            'bg-white/80': !isDark,
          }),
          thumbIcon: cn('w-4 h-4', {
            'text-default-800': isDark,
          }),
        }}
      />
    </div>
  )
}

SidebarThemeSelector.displayName = 'SidebarThemeSelector'
