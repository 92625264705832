import { PropsWithChildren } from 'react'

const PublicLayout = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex h-screen w-screen items-center justify-center overflow-hidden">
      <main>{children}</main>
    </div>
  )
}

PublicLayout.displayName = 'PublicLayout'

export default PublicLayout
