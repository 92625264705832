import { QKEY } from '@/constants/queryKeys'
import { CKEditorDocxToHtmlMetadata, DocumentVersion } from '@/models/api'
import { ProcessingStatus } from '@/models/enums'

import { useAuthorizedQuery } from '../useAuthorizedQuery'

export function useDocumentVersion(
  documentId: string | undefined,
  versionNumber: number | undefined,
) {
  return useAuthorizedQuery<DocumentVersion | null>(
    QKEY.DOCUMENT_VERSIONS.getByNumber(documentId, versionNumber),
    {
      enabled: !!documentId && !!versionNumber,
      queryFn: client =>
        client
          .get<{
            id: string
            num_pages: number
            version_number: number
            contents: string[]
            ckeditor_conversion: CKEditorDocxToHtmlMetadata
            document_info_processing_status: ProcessingStatus
            flags_processing_status: ProcessingStatus
            redlines_processing_status: ProcessingStatus
            has_track_changes: boolean
            has_comments: boolean
            created_at: string
            playbook_id: number
            html: string | null
            suggestions: DocumentVersion['suggestions']
            comments: DocumentVersion['comments']
            is_from_import: boolean
            external_file_id?: string
            updated_at: string
          }>(`/document/${documentId}/versions/${versionNumber}`)
          .then(res => {
            const d = res.data
            return {
              id: d.id,
              numPages: d.num_pages,
              versionNumber: d.version_number,
              contents: d.contents,
              ckeditorConversion: d.ckeditor_conversion,
              documentInfoProcessingStatus: d.document_info_processing_status,
              flagsProcessingStatus: d.flags_processing_status,
              redlinesProcessingStatus: d.redlines_processing_status,
              hasTrackChanges: d.has_track_changes,
              hasComments: d.has_comments,
              createdAt: d.created_at,
              updatedAt: d.updated_at,
              playbookId: d.playbook_id,
              html: d.html,
              suggestions: d.suggestions,
              comments: d.comments,
              isFromImport: d.is_from_import,
              externalFileId: d.external_file_id,
            }
          }),
      error: 'Failed to load document',
    },
  )
}
