import { memo } from 'react'

import { SidebarChildrenProps } from '@/models/components'
import { cn } from '@/utils'

import { BrandLogo } from './Logo'

export const SidebarBrand = memo(({ isCompact }: SidebarChildrenProps) => {
  return (
    <div
      className={cn('flex items-center gap-3 px-3', {
        'justify-center gap-0': isCompact,
      })}
    >
      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-foreground">
        <BrandLogo className="rounded-md text-background" />
      </div>
      <span
        className={cn('text-small font-bold uppercase opacity-100', {
          'w-0 opacity-0': isCompact,
        })}
      >
        Docsum
      </span>
    </div>
  )
})

SidebarBrand.displayName = 'SidebarBrand'
