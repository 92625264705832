import { useContext } from 'react'

import { AuthContext } from '@/contexts/auth/authContext'

export function useAuthContext() {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuthContext must be used within AuthContext')
  }

  return context
}
