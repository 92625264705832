import { useCallback, useEffect, useRef } from 'react'

import {
  DEFAULT_USER_ACTIVITY_EVENTS,
  IDLE_TIMEOUT_MS,
  PROMPT_TIMEOUT_MS,
} from '@/constants'

export interface IUseIdleTimerProps {
  events?: string[]
  onPrompt: () => void
  onIdle: () => void
  onActive: () => void
  idleTime?: number
  promptTimeout?: number
}

export const useIdleTimer = ({
  events = DEFAULT_USER_ACTIVITY_EVENTS,
  onPrompt,
  onIdle,
  onActive,
  idleTime = IDLE_TIMEOUT_MS,
  promptTimeout = PROMPT_TIMEOUT_MS,
}: IUseIdleTimerProps) => {
  const timerId = useRef<number | null>(null)
  const prompted = useRef<boolean>(false)
  const promptTime = useRef<number>(0)

  const isPrompted = useCallback<() => boolean>((): boolean => {
    return prompted.current
  }, [prompted])

  const destroyTimeout = (): void => {
    if (timerId.current) {
      window.clearTimeout(timerId.current)
      timerId.current = null
    }
  }

  const createTimeout = (time?: number): void => {
    destroyTimeout()
    timerId.current = window.setTimeout(toggleIdleState, time || idleTime)
  }

  const eventHandler = () => {
    if (prompted.current) return

    if (timerId.current) {
      createTimeout()
    }
  }

  const addEvents = () => {
    events.forEach(eventName => {
      window.addEventListener(eventName, eventHandler)
    })
  }

  const removeEvents = () => {
    events.forEach(eventName => {
      window.removeEventListener(eventName, eventHandler)
    })
  }

  const toggleIdleState = () => {
    if (!prompted.current) {
      promptTime.current = performance.now()
      prompted.current = true
      createTimeout(promptTimeout)
      onPrompt()
    } else {
      destroyTimeout()
      removeEvents()
      onIdle()
    }
  }

  const getRemainingTime = useCallback<() => number>((): number => {
    const timeLeft = Math.ceil(performance.now() - promptTime.current)

    return timeLeft < 0 ? 0 : Math.abs(promptTimeout - timeLeft)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptTime])

  const reset = useCallback<() => void>((): void => {
    destroyTimeout()
    addEvents()

    if (prompted.current) {
      onActive()
    }

    prompted.current = false
    promptTime.current = 0
    createTimeout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerId])

  useEffect(() => {
    const cleanAll = () => {
      destroyTimeout()
      removeEvents()
    }

    window.addEventListener('beforeunload', cleanAll)

    addEvents()
    createTimeout()

    return () => {
      window.removeEventListener('beforeunload', cleanAll)
      cleanAll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { reset, isPrompted, getRemainingTime }
}
