import { useMemo } from 'react'

import { AccountUserRoles } from '@/models/enums'

import { useUser } from './queries/useUser'

export function useCurrentUser() {
  const { data: user, status, isFetching, isLoading } = useUser()

  const canManageTeam = useMemo(
    () => !isFetching && user?.accountRoleId !== AccountUserRoles.MEMBER,
    [user?.accountRoleId, isFetching],
  )

  const shouldCompleteSignUp = useMemo(
    () => status !== 'pending' && user && user.policyAccepted === false,
    [status, user],
  )

  return {
    currentUser: user,
    isLoading: isFetching,
    canManageTeam,
    shouldCompleteSignUp,
    isFirstLoad: isLoading,
  }
}
