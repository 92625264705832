import {
  Tooltip as NextUiTooltip,
  TooltipProps as NextUITooltipProps,
} from '@nextui-org/tooltip'
import { forwardRef, ReactNode } from 'react'

interface TooltipProps
  extends Omit<
    NextUITooltipProps,
    'content' | 'delay' | 'closeDelay' | 'shadow' | 'classNames' | 'motionProps'
  > {
  tip: string | ReactNode | undefined
}

// TODO: Needs to be removed and use UITooltip instead
const Tooltip = forwardRef<HTMLElement | null, TooltipProps>(
  ({ children, tip, ...props }: TooltipProps, ref) => {
    return (
      <NextUiTooltip
        ref={ref}
        content={tip}
        delay={0}
        closeDelay={0}
        shadow={undefined}
        classNames={{
          content: [
            'text-tiny shadow rounded ring-1 ring-default-200 font-normal text-default-500 bg-white dark:font-light dark:text-default-700 dark:bg-[#1C1C1E] dark:ring-default-300/60',
          ],
        }}
        motionProps={{
          variants: {
            exit: {
              opacity: 0,
              transition: {
                duration: 0.1,
                ease: 'easeIn',
              },
            },
            enter: {
              opacity: 1,
              transition: {
                duration: 0.1,
                ease: 'easeOut',
              },
            },
          },
        }}
        {...props}
      >
        {children}
      </NextUiTooltip>
    )
  },
)

Tooltip.displayName = 'Tooltip'

export default Tooltip
