import type { ClassValue } from 'clsx'

import clsx from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'

const COMMON_UNITS = ['small', 'medium', 'large']

const nextUICustomClasses = {
  extend: {
    theme: {
      opacity: ['disabled'],
      spacing: ['divider'],
      borderWidth: COMMON_UNITS,
      borderRadius: COMMON_UNITS,
    },
    classGroups: {
      shadow: [{ shadow: COMMON_UNITS }],
      'font-size': [{ text: ['tiny', ...COMMON_UNITS] }],
      'bg-image': ['bg-stripe-gradient'],
    },
  },
}
const twMerge = extendTailwindMerge(nextUICustomClasses)

export function cnConcat(...inputs: ClassValue[]) {
  return clsx(inputs)
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
