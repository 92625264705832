import { ErrorBoundary } from '@highlight-run/react'
import { PropsWithChildren } from 'react'

import { UIButton } from '@/components/ui'

export function DSErrorBoundary({ children }: PropsWithChildren) {
  return (
    <ErrorBoundary
      fallback={
        <div className="bg-gray-30 fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center text-primary dark:bg-gray-700">
          <div className="text-center">
            <h3>Sorry! Something went wrong</h3>

            <p className="mt-4">
              We've been notified. Thanks for your patience 🙏
            </p>

            <div className="mt-8">
              <UIButton
                size="md"
                color="primary"
                onClick={() => {
                  // router.push will not work here due to behavior of error boundary
                  window.location.replace('/')
                }}
              >
                Back to home
              </UIButton>
            </div>
          </div>
        </div>
      }
      showDialog={false}
    >
      {children}
    </ErrorBoundary>
  )
}
