import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'
import React, { PropsWithChildren, useEffect } from 'react'

import { UIToaster } from '@/components/ui'
import { useAuthContext, useCurrentUser, useIsDarkTheme } from '@/hooks'

import { IdleTimerModal } from './IdleTimerModal'
import { Loader } from './Loader'
import PublicLayout from './Public'
import Sidebar from './Sidebar'

const publicPages = ['/login']

const protectedPages = ['/pass-setup', '/pass-reset']

const isPrivatePage = (pathname: string) =>
  !publicPages.includes(pathname) && !protectedPages.includes(pathname)

export const RootLayout = ({ children }: PropsWithChildren) => {
  const { session, isSigningOut, isInitialLogIn } = useAuthContext()

  const { currentTheme } = useIsDarkTheme()

  const router = useRouter()

  const {
    shouldCompleteSignUp,
    isLoading: isLoadingUser,
    isFirstLoad,
  } = useCurrentUser()

  const pathname = usePathname()

  const isRootPath = pathname === '/'

  useEffect(() => {
    if (!isInitialLogIn && !session && isPrivatePage(pathname)) {
      router.replace('/login')
    }
  }, [isInitialLogIn, pathname, router, session])

  if (!session) {
    return (
      <PublicLayout>
        {isInitialLogIn ||
        isLoadingUser ||
        ((isSigningOut || !publicPages.includes(pathname)) && !isRootPath) ? (
          <Loader />
        ) : (
          children
        )}
      </PublicLayout>
    )
  }

  if (
    (isLoadingUser && isFirstLoad) ||
    shouldCompleteSignUp ||
    protectedPages.includes(pathname) ||
    pathname === '/login'
  ) {
    return <PublicLayout>{isLoadingUser ? <Loader /> : children}</PublicLayout>
  }

  return (
    <div className="relative flex h-full min-h-full w-full items-stretch overflow-hidden">
      <UIToaster theme={currentTheme} />

      <IdleTimerModal />

      <Sidebar />

      <div className="min-w-0 min-h-0 h-screen flex flex-col flex-1">
        {children}
      </div>
    </div>
  )
}

RootLayout.displayName = 'RootLayout'
