import { API_URL } from '@/constants/api'
import { AuthContext } from '@/contexts/auth/authContext'
import axios from 'axios'
import { useRouter } from 'next/router'
import { useContext, useMemo } from 'react'

// TODO: depreacte once all legacy hooks use the new api client
export function useApiClient() {
  const { jwt } = useContext(AuthContext)
  const router = useRouter()

  const instance = useMemo(() => {
    if (!jwt) return null

    const instance = axios.create({
      baseURL: API_URL,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })

    instance.interceptors.response.use(
      response => response,
      error => {
        if (
          error.response &&
          (error.response.status === 403 || error.response.status === 404)
        ) {
          router.push(`/${error.response.status}`)
        }
        return Promise.reject(error)
      },
    )

    return instance
  }, [jwt, router])

  return instance
}
