import { AxiosInstance } from 'axios'

import {
  QueryFunctionContext,
  QueryKey,
  UseQueryOptions,
  useQuery,
} from '@tanstack/react-query'

import { useApiClient } from './useApiClient'

export type AuthorizedQueryFunction<T> = (
  apiClient: AxiosInstance,
  context: QueryFunctionContext<QueryKey>,
) => T | Promise<T>

export type AuthorizedQueryOptions<T> = Omit<
  UseQueryOptions<T>,
  'queryFn' | 'queryKey'
> & {
  queryFn: AuthorizedQueryFunction<T>
  error: string
}

/**
 * Wrapper on useQuery() that ensures all API requests are authenticated.
 *
 * @dev Passes an `apiClient` to the `queryFn` callback, and ensures the query is never called when the client is unavailable.
 *
 * @param key Query key
 * @param queryFn queryFn callback to be used by useQuery. Provides an apiClient and the query context as arguments.
 * @param error Error string to be displayed in the UI if a request fails.
 * @param enabled Query is disabled when false.
 * @returns useQuery instance
 */

// TODO: workaround top support migration to react-query v5 and avoid too many changes,
//       once we remove all the Authorized wrappers this should be removed.
export function useAuthorizedQuery<T>(
  key: QueryKey,
  { queryFn, error, enabled, ...opts }: AuthorizedQueryOptions<T>,
) {
  const apiClient = useApiClient()

  const apiClientQueryFn = apiClient
    ? (context: QueryFunctionContext) => queryFn(apiClient, context)
    : undefined

  return useQuery<T>({
    queryKey: key,
    queryFn: apiClientQueryFn,
    enabled: !!apiClientQueryFn && enabled,
    meta: {
      error,
    },
    ...(opts ?? {}),
  })
}
