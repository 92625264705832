import { SidebarItem } from '@/models/components'

export const baseNavigationItems: SidebarItem[] = [
  {
    key: 'workspace',
    title: 'Workspace',
    items: [
      {
        key: 'playbooks',
        href: '/playbooks',
        icon: 'solar:checklist-minimalistic-line-duotone',
        title: 'Playbooks',
      },
    ],
  },
  // {
  //   key: 'favorites',
  //   title: 'Favorites',
  //   items: [
  //     {
  //       key: 'shareholders',
  //       // icon: 'solar:users-group-rounded-linear',
  //       href: '#',
  //       title: 'Recently Signed',
  //     },
  //     {
  //       key: 'note_holders',
  //       // icon: 'solar:notes-outline',
  //       href: '#',
  //       title: 'Expiring',
  //     },
  //     {
  //       key: 'transactions_log',
  //       // icon: 'solar:clipboard-list-linear',
  //       href: '#',
  //       title: 'AI Contract Review',
  //     },
  //   ],
  // },
]
