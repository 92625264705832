abstract class BaseApi {
  protected resourcePath: string
  protected asChildPath: string
  protected readonly parentPath: string | undefined

  constructor(parentPath?: string) {
    this.resourcePath = ''
    this.asChildPath = ''
    this.parentPath = parentPath
  }

  protected getNestedPath(parentResourceId?: string) {
    if (!this.parentPath || !parentResourceId)
      throw new Error('Parent path or resource ID is missing')

    return `${this.parentPath}/${parentResourceId}/${this.asChildPath || this.resourcePath}`
  }
}

export default BaseApi
