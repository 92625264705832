import { Icon } from '@iconify/react'
import React from 'react'

import Tooltip from '@/components/shared/Tooltip'
import { UIButton } from '@/components/ui'
import { useSidebar } from '@/hooks'

export const SidebarCollapseButton = () => {
  const { canHide, setIsSidebarCollapsed, setIsSidebarHidden } = useSidebar()

  const hideSidebar = () => {
    setIsSidebarCollapsed(true)
    canHide && setIsSidebarHidden(true)
  }

  return (
    <Tooltip
      tip={`${canHide ? 'Hide' : 'Collapse'} sidebar`}
      placement="bottom-end"
    >
      <UIButton
        isIconOnly
        className="flex h-8 w-8 rounded-full text-default-600"
        size="sm"
        variant="light"
      >
        <Icon
          className="cursor-pointer dark:text-primary-foreground/60 [&>g]:stroke-[1px]"
          height={24}
          icon="solar:round-alt-arrow-left-line-duotone"
          width={24}
          onClick={hideSidebar}
        />
      </UIButton>
    </Tooltip>
  )
}
