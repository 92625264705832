import {
  HydrationBoundary,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import React, { PropsWithChildren, useState } from 'react'
import { useRouter } from 'next/router'

import { useToast } from '@/components/ui'
import { ApiClientError } from '@/services/api/client'
import { useLogOut } from '@/hooks'

const DEFAULT_STALE_TIME_2_MIN_IN_MS = 2 * 60 * 1000

const UNAUTHORIZED_STATUS_CODE_MAP = {
  401: 'Unauthorized - Expired session or invalid token',
  404: 'Not found - User in token does not exist',
}

const ReactQueryProvider = ({
  dehydratedState,
  children,
}: PropsWithChildren<{ dehydratedState: unknown }>) => {
  const { toast } = useToast()

  const router = useRouter()

  const logOut = useLogOut()

  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error, query) => onErrorHandler(error, query.options.meta),
        }),
        mutationCache: new MutationCache({
          onError: (error, _, __, mutation) =>
            onErrorHandler(error, mutation.options.meta),
        }),
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: false,
            staleTime: DEFAULT_STALE_TIME_2_MIN_IN_MS,
          },
        },
      }),
  )

  const onErrorHandler = (error: unknown, meta?: Record<string, unknown>) => {
    const knownAuthorizationErrorStatus = (error as ApiClientError).response
      ?.status
    if (
      knownAuthorizationErrorStatus &&
      Object.keys(UNAUTHORIZED_STATUS_CODE_MAP).includes(
        knownAuthorizationErrorStatus.toString(),
      )
    ) {
      console.error(
        UNAUTHORIZED_STATUS_CODE_MAP[
          knownAuthorizationErrorStatus.toString() as never
        ],
      )

      logOut(() => router.push('/login'))
      return
    }

    console.error('Error found', error)

    // TODO: known errors from query hooks
    // This should be handled by using a standard error obj/structure from the API.
    // eg code, not injecting metadata in each react query or mutation
    const knownLocalError = meta?.['error']
    if (typeof knownLocalError === 'string') {
      toast.error(knownLocalError)
      return
    }

    const unknownGlobalErrorMessage =
      'Oops! Something went wrong. Please try again or contact support (support@docsum.ai).'
    toast.error('Error', { description: unknownGlobalErrorMessage })
  }

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={dehydratedState}>{children}</HydrationBoundary>
    </QueryClientProvider>
  )
}

export default ReactQueryProvider
