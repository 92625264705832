import { useCallback } from 'react'

import { supabaseComponentClient } from '@/plugins/supabase'
import { useAuthContext } from './useAuthContext'

export const useLogOut = () => {
  const { setIsSigningOut } = useAuthContext()

  return useCallback((onSignedOut?: () => void) => {
    setIsSigningOut(true)
    supabaseComponentClient.auth.signOut().then(() => onSignedOut?.())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
