import { QKEY } from '@/constants/queryKeys'
import { User } from '@/models/user'

import { useAuthorizedQuery } from '../useAuthorizedQuery'

export function useUser() {
  return useAuthorizedQuery<User | null>(QKEY.USER, {
    queryFn: client =>
      client.get<User | undefined>('/user/me').then(res => {
        const { data: user } = res

        if (!user) return null

        return user
      }),
    error: 'There was a problem getting your profile info',
  })
}
