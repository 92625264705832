import { Icon } from '@iconify/react'
import React, { useCallback, useMemo } from 'react'
import { ExternalToast, ToastT, Toaster, toast as sonnerToast } from 'sonner'

export enum ToastType {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info',
}

type UIToastProps = {
  theme: 'light' | 'dark' | 'system'
}

type CustomExternalToast = Omit<ExternalToast, 'classNames'>

const toastFuncMap = {
  [ToastType.ERROR]: sonnerToast.error,
  [ToastType.WARNING]: sonnerToast.warning,
  [ToastType.SUCCESS]: sonnerToast.success,
  [ToastType.INFO]: sonnerToast.info,
}

const toastThemeMap: { [key in ToastType]: ToastT['classNames'] } = {
  [ToastType.ERROR]: {
    title: 'text-red-700 dark:text-red-400',
    icon: 'text-red-500 dark:text-red-400/90',
    toast: 'border-red-600/20 dark:border-red-400/20',
  },
  [ToastType.WARNING]: {
    title: 'text-yellow-800 dark:text-yellow-500',
    icon: 'text-yellow-500 dark:text-yellow-500/90',
    toast: 'border-yellow-600/20 dark:border-yellow-500/20',
  },
  [ToastType.SUCCESS]: {
    title: 'text-lime-600 dark:text-lime-500',
    icon: 'text-lime-500 dark:text-lime-500/90',
    toast: 'border-lime-600/20 dark:border-lime-500/20',
  },
  [ToastType.INFO]: {
    title: 'text-sky-700 dark:text-sky-500',
    icon: 'text-sky-500 dark:text-sky-500/80',
    toast: 'border-sky-700/20 dark:border-sky-500/20',
  },
}

type ToastFunction = (
  message: string,
  data?: CustomExternalToast | undefined,
) => string | number

type DocsumToast = {
  error: ToastFunction
  warning: ToastFunction
  success: ToastFunction
  info: ToastFunction
  message: typeof sonnerToast.message
  dismiss: typeof sonnerToast.dismiss
}

export const useToast = () => {
  const createToast = useCallback(
    (
      type: ToastType,
      message: string,
      data?: CustomExternalToast | undefined,
    ) => {
      const toastFunc = toastFuncMap[type]
      if (!toastFunc) return sonnerToast.message(message, data)

      return toastFunc(message, {
        ...data,
        classNames: toastThemeMap[type],
      })
    },
    [],
  )

  const toast = useMemo<DocsumToast>(
    () => ({
      error: (...args) => createToast(ToastType.ERROR, args[0], args[1]),
      warning: (...args) => createToast(ToastType.WARNING, args[0], args[1]),
      success: (...args) => createToast(ToastType.SUCCESS, args[0], args[1]),
      info: (...args) => createToast(ToastType.INFO, args[0], args[1]),
      message: sonnerToast.message,
      dismiss: sonnerToast.dismiss,
    }),
    [createToast],
  )

  return { toast }
}

const UIToaster = ({ theme }: UIToastProps) => {
  return (
    <Toaster
      closeButton
      visibleToasts={5}
      gap={20}
      theme={theme}
      position="bottom-right"
      toastOptions={{
        classNames: {
          toast: 'dark:bg-content2 flex items-start',
          description: 'font-light dark:text-default-800',
          icon: '[&>svg]:h-5 [&>svg]:w-5 pt-[5px]',
          closeButton:
            'border-none left-full !bg-transparent -translate-x-7 top-1.5 text-default-500 hover:text-default-foreground !hover:bg-red-500',
        },
      }}
      icons={{
        error: <Icon icon="solar:danger-circle-bold" />,
        success: <Icon icon="solar:check-circle-bold" />,
        info: <Icon icon="solar:info-circle-bold" />,
        warning: <Icon icon="solar:danger-triangle-bold" />,
      }}
    />
  )
}

UIToaster.displayName = 'UIToaster'

export default UIToaster
