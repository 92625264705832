import { useMemo } from 'react'
import { useAccounts } from './queries'
import { useUser } from './queries/useUser'

export function useSelectedAccount() {
  const accounts = useAccounts()

  const { data: user } = useUser()

  return useMemo(() => {
    if (!accounts.data?.length || !user) return

    return accounts.data.find(account => account.id === user?.accountId)
  }, [accounts, user])
}
