import { View, ViewAsNavigation } from '@/models/api'

import BaseApi from './base'
import { apiClient, getHeaders, type ApiRequestConfig } from './client'

export class ViewsApi extends BaseApi {
  constructor() {
    super()
    this.resourcePath = 'favorite-views'
  }

  async getByIdOrSlug(
    idOrSlug: string,
    { signal, ...configProps }: ApiRequestConfig = {},
  ) {
    return apiClient.get<View>(this.resourcePath, idOrSlug, {
      signal,
      ...getHeaders(configProps),
    })
  }

  async getAllAsNavigation({ signal, ...configProps }: ApiRequestConfig = {}) {
    return apiClient.get<ViewAsNavigation[]>(this.resourcePath, 'navigation', {
      signal,
      ...getHeaders(configProps),
    })
  }
}

const viewsApi = new ViewsApi()

export default viewsApi
