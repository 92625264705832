import { Provider, createStore } from 'jotai'
import { PropsWithChildren } from 'react'

export const globalStore = createStore()

const AtomGlobalProvider = ({ children }: PropsWithChildren) => {
  return <Provider store={globalStore}>{children}</Provider>
}

export default AtomGlobalProvider
