export function formatFilename(filename: string | undefined) {
  if (!filename) return undefined

  const splitFilename = filename.split('.')
  return splitFilename.slice(0, splitFilename.length - 1).join('.')
}

export function toPx(value: number) {
  return `${value}px` as const
}

export function getFullName({
  firstName,
  lastName,
}: {
  firstName?: string
  lastName?: string
} = {}) {
  if (!firstName && !lastName) return ''

  return `${firstName} ${lastName}`.trim()
}

export function getInitials(fullname: string) {
  return fullname
    .split(' ')
    .slice(0, 2)
    .map(name => name[0])
    .join('')
    .toUpperCase()
}

export const snakeToTitleCase = (str: string | undefined): string => {
  if (!str) return ''

  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const formatCurrency = (amount: number, currency: string) =>
  Intl.NumberFormat('en-US', {
    currency,
    style: 'currency',
    minimumFractionDigits: 0,
  }).format(amount)

export const getCurrencySymbol = (currency: string, locale = 'en-US') => {
  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()
}
