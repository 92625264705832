import { useQuery } from '@tanstack/react-query'

import { QKEY } from '@/constants/queryKeys'
import { viewsApi } from '@/services/api'
import { DEFAULT_STATIC_DATA_CACHE_20_MIN_IN_MS } from '@/constants/api'

export function useGetAllViews() {
  return useQuery({
    queryKey: QKEY.VIEWS.getAllAsNavigation,
    queryFn: ({ signal }) => viewsApi.getAllAsNavigation({ signal }),
    gcTime: DEFAULT_STATIC_DATA_CACHE_20_MIN_IN_MS,
  })
}
