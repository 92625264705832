import { useHydrateAtoms } from 'jotai/utils'
import { PropsWithChildren } from 'react'

import { isSidebarCollapsedAtom, isSidebarHiddenAtom } from './global/atoms'
import { globalStore } from './global/provider'
import { AtomSsrHydratorProps } from './types'

const AtomGlobalSsrHydrator = ({
  dehydratedData,
  children,
}: PropsWithChildren<AtomSsrHydratorProps>) => {
  const { global } = dehydratedData || {}
  // TODO: useful to init atoms with server data like user preferences
  const globalAtomsToHydrate = global
    ? [
        [isSidebarCollapsedAtom, global.isSidebarCollapsed || false],
        [isSidebarHiddenAtom, global.isSidebarHidden || false],
      ]
    : []

  useHydrateAtoms(globalAtomsToHydrate as never, {
    store: globalStore,
  })

  return children
}

export default AtomGlobalSsrHydrator
