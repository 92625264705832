import { DEFAULT_THEME_MATCH_MEDIA } from '@/constants'
import { AppTheme } from '@/models'
import { themeAtom } from '@/services/state/global'

import { useAtom } from 'jotai'
import { useCallback, useEffect, useMemo } from 'react'

const DARK_CLASS = 'dark'

export function useIsDarkTheme() {
  const [theme, setTheme] = useAtom(themeAtom)

  const isDark = useMemo(
    () =>
      theme === AppTheme.Dark ||
      // TODO: useful when we support system option
      (theme === AppTheme.System &&
        window.matchMedia(DEFAULT_THEME_MATCH_MEDIA).matches),
    [theme],
  )

  const currentTheme = useMemo(
    () => (isDark ? AppTheme.Dark : AppTheme.Light),
    [isDark],
  )

  const toggleTheme = useCallback(() => {
    setTheme(isDark ? AppTheme.Light : AppTheme.Dark)
  }, [isDark, setTheme])

  useEffect(() => {
    if (isDark) {
      document.documentElement.classList.add(DARK_CLASS)
    } else {
      document.documentElement.classList.remove(DARK_CLASS)
    }
  }, [isDark])

  return { isDark, toggleTheme, currentTheme }
}
